import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import InputField from "../../Shared/inputField/inputField";

function FaceLivenessPopup({ handleResponse, name }: any) {
  const errorObject = {
    consentError: false,
    faceError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [faceMatch, setFaceMatch] = React.useState(false);
  const userFaceref = React.useRef<any>("");
  const [loading, setLoading] = React.useState(false);

  function handleCheck() {
    const consent = isChecked === true ? "Y" : "N";
    const face = userFaceref.current.value;

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }

    if (faceMatch && face === "") {
      console.log("face is empty");

      setError(() => ({
        ...error,
        faceError: true,
      }));

      return false;
    }

    setLoading(true);
    handleResponse({ consent, face }, setLoading);
  }

  const isValidFace = React.useCallback(() => {
    if (userFaceref.current.value === "") {
      setError(() => ({
        ...error,
        faceMatch: true,
      }));
    }
  }, [userFaceref.current.value]);

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <div className="pl-4 mt-4">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(e: any) => {
              setFaceMatch(e.target.checked);
            }}
          />
          <p className={`${Styles.ackText} mb-4`}>Is face match required ?</p>
          {faceMatch && (
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Face To Match
              </label>
              <InputField
                inputRef={userFaceref}
                isError={error.faceError}
                onChange={isValidFace}
                purpose="forName"
                text="lowerCase"
                className="w-50 p-2"
                style={{
                  border: "1px solid #e8e8e8",
                  borderRadius: 8,
                  outline: "none",
                }}
              />
              {error.faceError && (
                <ErrorMessage>Please provide name!</ErrorMessage>
              )}
            </div>
          )}
        </div>
        <div className="pl-4 pb-4 mt-4">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(e: any) => {
              setIsChecked(e.target.checked);
            }}
          />
          <p className={`${Styles.ackText}`}>
            I hearby agree, to let zoop.one verify my data for verification
          </p>
          {error.consentError && (
            <ErrorMessage>Please provide consent!</ErrorMessage>
          )}
        </div>
        <div className={`${Styles.buttonGroup}`}>
          <Button
            id="id-submit-button-face-liveness"
            hoveredStyle={primaryButtonHoverStylePopup}
            disabled={loading}
            size={MEDIUM}
            isLoading={loading}
            style={primaryButtonStylePopup}
            onClick={() => {
              setTimeout(() => setError({ ...errorObject }), 2000);
              handleCheck();
            }}
          >
            Run Verification
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FaceLivenessPopup;
