//Dashboard render 2 conditional components based on the onboarding status of the user

// Hooks
import React, { useEffect, useState } from "react";

// Custom components
import ProductsUsage from "./product-usage/productsUsage";
import TransactionStats from "./transaction-stats/transactionStats";
import MultipurposeCard from "./multipurpose-card/multipurposeCard";
import DataTable from "../../../Shared/data-table/datatable";
import Loading from "../../../Shared/loading/loading";
import CheckList from "./check-list/checkList";
import { Link } from "react-router-dom";

// Hooks
import { useTitle } from "../../../Shared/useTitle";

// Functions
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";

// Types
import { envTypes } from "../../../constants/envTypes";

// Constants
import { ONBOARDING_STATUS } from "../../../constants/onboardingStatuses";
import { ACCENTCOLOR, WHITE } from "../../../Shared/colors";

// Styles
import Styles from "./dashboard.module.scss";

const tableHeader = [
  {
    name: "App Name",
    className: Styles.date,
  },
  {
    name: "Keys",
    className: Styles.id,
  },
  {
    name: "Products",
    className: Styles.product_name,
  },
];
const plan_types = {
  PREPAID: "PREPAID",
  POSTPAID: "POSTPAID",
};

function Dashboard() {
  const org_id = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const [appData, setAppData] = useState([]);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(0);
  const [onboardingStatus, setOnboardingStatus] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [overAllLoading, setOverallLoading] = React.useState(false);
  const [summaryLoading, setSummaryLoading] = React.useState(false);
  const [overallStats, setOverallStats] = React.useState({
    subscribed_products_count: 0,
    pending_products_count: 0,
    plan_validity: "",
    wallet_balance: 0,
  });
  const [env, setEnv] = React.useState(envTypes.TEST);
  const [selectedApp, setSelectedApp] = React.useState("");
  const [selectedAppInfo, setSelectedAppInfo] = React.useState<any>();
  const [selectedAppId, setSelectedAppId] = React.useState("");
  const [productCount, setProductCount] = React.useState(0);
  const [planType, setPlanType] = React.useState();
  const [tranxnVolume, setTranxnVolume] = useState("0");
  const [latestApprovedProd, setLatestApprovedProduct] = React.useState("");
  const [productOptions, setProductOptions] = useState<any>([]);
  const xUserId = React.useMemo(() => {
    const xuserid: any = JSON.parse(user)?._id;
    return xuserid;
  }, [user]);
  useTitle("Zoop Customer Platform | Dashboard");

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
  }

  async function getAllAppNames(page: string) {
    if (onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED) {
      setOverallLoading(true);
      try {
        const data = await callApi(`/app?page=${page}`, {
          method: "GET",
        });
        setAppData(data);
        if (
          onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED &&
          data.length > 1
        ) {
          setSelectedApp(data[1].name);
          setSelectedAppInfo(data[1]);
          setSelectedAppId(data[1]._id);
        } else {
          setSelectedApp(data[0].name);
          setSelectedAppInfo(data[0]);
          setSelectedAppId(data[0]._id);
        }
        setOverallLoading(false);
      } catch (err) {
        setOverallLoading(false);
        toastTimeout(true, "error", (err as Error).message);
      }
    }
  }

  async function getOrgBalance() {
    try {
      const res = await callApi("/summary", {
        method: "GET",
      });
      setOverallStats(res);
      setProductCount(res.subscribed_products_count);
    } catch (err) {
      toastTimeout(true, "error", "Failed to fetch");
    }
  }

  async function getOrgInfo() {
    setLoading(true);
    try {
      const response = await callApi(`/organisation/${org_id}`, {
        method: "GET",
      });
      if (response?.activePlan != undefined) {
        const { activePlan, onboarding_status, country_code } = response;
        localStorage.setItem("country_code", response.country_code);
        setPlanType(activePlan?.plan_type);
        setOnboardingStatus(onboarding_status);
        setCountryCode(country_code);
      }
    } catch (err) {
      if (err instanceof Error) {
        toastTimeout(true, "error", err?.message);
      } else {
        toastTimeout(true, "error", "An unknown error occurred.");
      }
    } finally {
      setLoading(false);
    }
  }

  async function getAllProductsForOrg() {
    const res = await callApi(`/product?subscription_status=subscribed`, {
      method: "GET",
    });

    setProductOptions([
      { label: "All Products", value: "" },
      ...[
        ...res.map((product: any) => ({
          label: product?.name,
          value: product?.arn,
        })),
      ],
    ]);

    // {KEEP THIS COMMENT} filtering will remove pre-approved products from the list
    // .filter((product: any) => !product.auto_approval)
    const temp = res.sort((a: any, b: any) => b.updatedAt - a.updatedAt)[0];
    setLatestApprovedProduct(temp?.name);
  }

  // getting org info api
  useEffect(() => {
    getOrgInfo();
    getOrgBalance();
    getAllProductsForOrg();
  }, [org_id]);

  // get all apps api without keys and service assess
  useEffect(() => {
    getAllAppNames("dashboard");
  }, [org_id, onboardingStatus]);

  //get overall volume of transactions
  useEffect(() => {
    async function getTransactionVolume() {
      try {
        const res = await callApi(
          `/organisation/${org_id}?action=get_org_total_transaction_counts`,
          {
            method: "GET",
            init: {
              headers: {
                "x-user-id": xUserId,
              },
            },
          }
        );
        setTranxnVolume(res.total_count);
      } catch (error) {
        console.log(error);
      }
    }
    getTransactionVolume();
  }, []);

  const tableHeaderCustom = (
    <div className="my-3 text-dark font-weight-bold">
      <p>Your Apps</p>
      <div className="d-flex justify-content-between align-items-center p-2">
        <p className={`mb-0 ${Styles.formLabel}`}>
          {appData.length} Apps Running
        </p>
        <Link
          to="/application/manage-apps"
          id="id-manage-apps-button"
          className={`card_button px-2 py-2 px-4`}
          style={{
            background: ACCENTCOLOR,
            color: WHITE,
            fontSize: 12,
          }}
        >
          Manage Apps
        </Link>
      </div>
    </div>
  );

  return (
    <div className={`container-fluid p-0 ${Styles.container}`}>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {loading || overAllLoading || summaryLoading ? (
        <div
          style={{ height: "80vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        // The user will not be able to see the dashboard unless the Onboarding
        //  has successfully Completed
        <div className="px-4 py-1">
          {onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED ? (
            <div>
              <div className="d-flex">
                <div className="d-flex flex-column" style={{ width: "60%" }}>
                  <ProductsUsage
                    productOptions={productOptions}
                    preSelectedDatePreference={"Last 30 days"}
                  />
                  <TransactionStats
                    appDetails={selectedAppInfo}
                    appData={appData}
                  />
                </div>

                <div
                  className="d-flex flex-column ml-1"
                  style={{ width: "40%" }}
                >
                  <div className="d-flex">
                    <MultipurposeCard
                      purpose="trans_info"
                      tranxnVolume={tranxnVolume}
                    />
                    <MultipurposeCard
                      purpose="subscription_info"
                      productCount={productCount}
                    />
                  </div>
                  <div className="d-flex">
                    <MultipurposeCard
                      purpose="avl_balance_info"
                      availableBalance={
                        planType === plan_types.PREPAID
                          ? overallStats.wallet_balance
                          : "NA"
                      }
                    />
                    <MultipurposeCard
                      purpose="trial_info"
                      latestApprovedProd={latestApprovedProd || "NA"}
                    />
                  </div>
                  <div className="pl-1">
                    <DataTable
                      headers={[...tableHeader]}
                      loading={loading}
                      rows={appData}
                      setActivePageNumber={setActivePageNumber}
                      totalNumberOfPages={totalNumberOfPages}
                      tableHeader={tableHeaderCustom}
                      purpose="forDashboard_mini"
                      bodyClassName={Styles?.bodyClassName}
                      pagination={false}
                    >
                      {appData.length ? (
                        appData.map((app: any, index: number) => {
                          return (
                            <tr key={`${app.id}-${index}`} className="mt-3">
                              <td>
                                <p className="font datatable_text">
                                  {app.name}
                                </p>
                              </td>
                              <td>
                                <p className="font datatable_text">
                                  {app.keys}
                                </p>
                              </td>
                              <td>
                                <p className="font datatable_text">
                                  {app.service_access}
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="d-flex align-items-center justify-content-center">
                          No data found
                        </div>
                      )}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CheckList
              onboardingStatus={onboardingStatus}
              countryCode={countryCode}
            />
          )}
        </div>
      )}

      <div style={{ height: 50 }} />
    </div>
  );
}

export default Dashboard;
